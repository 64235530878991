import Geocode from 'react-geocode'
import {
  CLEAR_APP_VARIANTS,
  SET_EXPERIMENT_VARIANT,
  SET_APP_MESSAGE,
  CLEAR_APP_STATE,
  SET_APP_ABOVE_HEADER_MESSAGE,
  SET_APP_IS_WELCOME_BACK_PAGE,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  DELETE_NOTIFICATION,
  CREATE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_PROVEN_HEADER_MODE,
  SET_APP_EDITION,
  SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE,
  SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE
} from '../constants/actionTypes'

export const setExperimentVariant = (experimentName, variant) => ({
  type: SET_EXPERIMENT_VARIANT,
  payload: {
    experimentName,
    variant
  }
})

export const clearAppVariants = () => dispatch => {
  dispatch({
    type: CLEAR_APP_VARIANTS
  })
}

export const setAppMessage =
  (message = '', touchedFields = []) =>
  dispatch => {
    dispatch({
      type: SET_APP_MESSAGE,
      message,
      touchedFields
    })
  }

export const clearAppState = () => dispatch => {
  dispatch({
    type: CLEAR_APP_STATE
  })
}

export const getZipCodeFromBrowser = () => {
  return new Promise(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          Geocode.fromLatLng(latitude, longitude).then(
            ({ results }) => {
              results.map(i => {
                if (i.types[0] === 'postal_code') {
                  if (i.address_components[0].long_name.length > 3) {
                    return resolve(i.address_components[0].long_name)
                  }
                }
              })
              return resolve()
            },
            () => {
              resolve()
            }
          )
        },
        () => {
          resolve()
        }
      )
    } else {
      resolve()
    }
  })
}

export const setAppAboveHeaderMessage = aboveHeaderMsgRenderContent => dispatch => {
  dispatch({
    type: SET_APP_ABOVE_HEADER_MESSAGE,
    aboveHeaderMsgRenderContent
  })
}

export const setIsWelcomeBackPage = isWelcomeBackPage => dispatch => {
  dispatch({
    type: SET_APP_IS_WELCOME_BACK_PAGE,
    isWelcomeBackPage
  })
}

export const openMobileMenu = () => ({
  type: OPEN_MOBILE_MENU
})

export const closeMobileMenu = () => ({
  type: CLOSE_MOBILE_MENU
})

export const createNotification = (strong, text, htmlContent, options) => {
  const key = options?.key

  return {
    type: CREATE_NOTIFICATION,
    key: key || new Date().getTime() + Math.random(),
    htmlContent,
    strong,
    text,
    options
  }
}

export const closeNotification = key => ({
  type: CLOSE_NOTIFICATION,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const deleteNotification = key => ({
  type: DELETE_NOTIFICATION,
  key
})

export const setProvenHeaderMobileMode = isMobileMode => ({
  type: SET_PROVEN_HEADER_MODE,
  payload: { isMobileMode }
})

export const setAppEdition = edition => ({
  type: SET_APP_EDITION,
  edition
})

export const setSubscriptionOptionForNextActionOnCurrentProductPage = () => ({
  type: SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE
})
export const setOneTimeOptionForNextActionOnCurrentProductPage = () => ({
  type: SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE
})
