import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import Typewriter from 'typewriter-effect'
import ProvenShape from '../../../../../../shared/proven-shape'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createProvenTheme } from '../../../../../../../styles/theme-proven'
import BeforeAfterCarouselImage from '../../../../../../shared/before-after-carousel/image'
import { MobileIngredient, DesktopIngredient } from './ingredient'
import { PUBLIC_SYSTEM_PRODUCT_URL } from '../../../../../../../constants/products'
import AnimatedNumber from '../../../../../../shared/AnimatedNumber'
import useIsInViewport from 'hooks/useIsInViewport'

const theme = createProvenTheme()
const spacing = theme.spacing

function ConcernItem({
  areIngredientsOnTheLeftSide,
  ingredients,
  title,
  description,
  percentage,
  percentageDescription,
  backgroundImageUrl,
  afterImage,
  beforeImage,
  sourceLabel
}) {
  const isSerumProductPage = useRouteMatch(['/account/shop/serum', '/personalized-serum'])
  const isShopPage = useRouteMatch('/account/shop')
  const isPublicSystemProductPage = useRouteMatch(PUBLIC_SYSTEM_PRODUCT_URL)
  const isCongratulationsPage = useRouteMatch('/account/congratulations')
  const isCongratulationsSerumPage = useRouteMatch('/account/congratulations/serum')
  const isSystemProductPage = useRouteMatch('/account/shop/skin')
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const percentRef = useRef()
  const titleRef = useRef()
  const isTitleInViewport = useIsInViewport(titleRef)
  const [showTitle, setShowTitle] = useState(false)

  useEffect(() => {
    if (isTitleInViewport) {
      setShowTitle(true)
    }
  }, [isTitleInViewport])

  const showEfficacySlides =
    !isCongratulationsSerumPage && !isSerumProductPage && beforeImage && afterImage
  const showPercentage = !isCongratulationsSerumPage && !isSerumProductPage && !!percentage

  const gridTemplateAreas = !isMdUp
    ? `"title title title title"
       "efficacySlides efficacySlides efficacySlides efficacySlides"
       "firstPercentage firstPercentage firstPercentage firstPercentage"
       "description description description description"
       "ingredients ingredients ingredients ingredients"`
    : areIngredientsOnTheLeftSide
    ? `"ingredients ingredients ingredients ingredients ingredients . title title title title title title"
    "ingredients ingredients ingredients ingredients ingredients . efficacySlides efficacySlides efficacySlides efficacySlides efficacySlides efficacySlides"
    "ingredients ingredients ingredients ingredients ingredients . firstPercentage firstPercentage firstPercentage firstPercentage firstPercentage firstPercentage"
    "ingredients ingredients ingredients ingredients ingredients . description description description description description description"`
    : `"title title title title title title . ingredients ingredients ingredients ingredients ingredients"
       "efficacySlides efficacySlides efficacySlides efficacySlides efficacySlides . . ingredients ingredients ingredients ingredients ingredients"
       "firstPercentage firstPercentage firstPercentage firstPercentage firstPercentage . . ingredients ingredients ingredients ingredients ingredients"
       "description description description description description . . ingredients ingredients ingredients ingredients ingredients"
     `

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: isMdUp ? 'repeat(12, 1fr)' : 'repeat(4, 1fr)',
          gridTemplateRows: isMdUp ? '1fr auto auto auto' : 'auto auto auto auto auto',
          alignItems: 'flex-end',
          gridTemplateAreas: gridTemplateAreas
        }}
      >
        {backgroundImageUrl && (
          <Box
            data-testid={`congratulation-concerns-concernItem-background`}
            sx={{
              mx: { xs: -2, md: -8, lg: '-150px', xxl: '-10%' },
              position: 'absolute',
              transform: !isMdUp ? `translateY(${spacing(-20)})` : undefined,
              left: isMdUp ? (areIngredientsOnTheLeftSide ? 0 : undefined) : undefined,
              right: isMdUp ? (!areIngredientsOnTheLeftSide ? 0 : undefined) : 0,
              height: { xs: '100%', lg: spacing(100.875) },
              width: { xs: '100%', lg: spacing(132.625) },
              background: `${
                isMdUp
                  ? ''
                  : 'linear-gradient(255.13deg, rgba(255, 255, 255, 0) 27.77%, #FFFFFF 48.62%), '
              }url(${backgroundImageUrl}) top ${isMdUp ? 'left' : 'right'} no-repeat`,
              backgroundPositionX: isMdUp
                ? `${spacing((areIngredientsOnTheLeftSide ? -1 : 1) * 33.75)}`
                : undefined,
              backgroundSize: !isMdUp ? 'contain' : undefined,
              boxShadow: 'unset',
              borderRadius: 'unset'
            }}
          />
        )}

        <Box zIndex={4} sx={{ gridArea: 'title', pb: { xs: 3, md: 5 } }}>
          <Typography variant="title1" color="gray.blueGray" ref={titleRef}>
            {isSystemProductPage?.isExact ||
            (!isShopPage && (isPublicSystemProductPage || isCongratulationsPage))
              ? 'Your system addresses'
              : 'YOUR SKINCARE ADDRESSES'}
            <Typography zIndex={4} pt={1} variant="h3" color="primary.main">
              <Typewriter
                options={{
                  strings: [title],
                  autoStart: showTitle,
                  deleteSpeed: Infinity
                }}
              />
            </Typography>
          </Typography>
          {isMdUp && (
            <Typography
              variant="title1"
              color="gray.elysian"
              component="div"
              sx={{ textTransform: 'uppercase', mb: 1, mt: 4 }}
            >
              Personalized Ingredients
            </Typography>
          )}
          <Typography variant="body2" color="gray.blueGray" mt={1}>
            {description}
          </Typography>
        </Box>

        {showEfficacySlides && (
          <Box sx={{ gridArea: 'efficacySlides', zIndex: 4, pb: 2 }}>
            {isMdUp && (
              <Typography
                variant="title1"
                color="gray.elysian"
                component="div"
                sx={{ textTransform: 'uppercase', mb: 2 }}
              >
                Clinically Proven efficacy
              </Typography>
            )}
            <Box
              sx={{
                margin: 0,
                outline: 'none',
                maxHeight: { xs: '200px', md: '260px' },
                display: 'flex',
                '& figure': {
                  maxWidth: '461px',
                  width: ['100%', '100%', '100%', '461px']
                }
              }}
              className="slick-center"
            >
              <BeforeAfterCarouselImage
                after={afterImage}
                before={beforeImage}
                position={40}
                singleSlide
                withoutLazy
              />
            </Box>
            <Typography variant="footnoteLight" color="gray.blueGray">
              {sourceLabel}
            </Typography>
          </Box>
        )}

        {showPercentage && percentage && (
          <Box gridArea="firstPercentage" sx={{ pb: 5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ProvenShape
                zIndex={1}
                baseWidth={isMdUp ? 3.6 : 3}
                baseHeight={isMdUp ? 3.6 : 3}
                viewBox="0 0 32 32"
                name="circle"
                backgroundColor="gray.lightBlue"
                justifyContent="flex-start"
              >
                <Typography
                  pr={2}
                  pl={2}
                  zIndex={4}
                  variant="h3"
                  color="primary.main"
                  display="flex"
                  alignItems="center"
                  ref={percentRef}
                >
                  <AnimatedNumber value={parseInt(percentage)} parentRef={percentRef} />%
                </Typography>
              </ProvenShape>
              {percentageDescription && (
                <Typography alignSelf="start" zIndex={4} variant="title2" color="primary.main">
                  {percentageDescription}
                </Typography>
              )}
            </Box>
          </Box>
        )}

        {!isMdUp && (
          <Box
            zIndex={4}
            sx={{
              gridArea: 'description',
              pb: {
                xs: 4,
                md: showEfficacySlides && showPercentage ? 0 : showPercentage ? '20%' : '30%'
              }
            }}
          >
            <Typography
              variant="title1"
              color="gray.elysian"
              component="div"
              sx={{ textTransform: 'uppercase' }}
            >
              Personalized Ingredients
            </Typography>
          </Box>
        )}

        {isMdUp ? (
          <Stack gridArea="ingredients" alignSelf={{ md: 'start' }}>
            {(ingredients || [])
              .slice(0, 3)
              .map(
                (i, index) =>
                  i && (
                    <DesktopIngredient
                      key={`ingredient-${i.ingredient_name}`}
                      areIngredientsOnTheLeftSide={areIngredientsOnTheLeftSide}
                      position={index}
                      ingredientData={i}
                    />
                  )
              )}
          </Stack>
        ) : (
          <Box
            gridArea="ingredients"
            sx={{
              minHeight: '176px',
              pl: 2.5,
              pt: 3.75,
              maxWidth: '100vw',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                '-webkit-appearance': 'none'
              },
              '&::-webkit-scrollbar-track': {
                'background-color': 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                'background-color': 'transparent'
              }
            }}
          >
            <Stack flexDirection="row" gap={4}>
              {(ingredients || [])
                .slice(0, 3)
                .map(
                  i =>
                    i && (
                      <MobileIngredient
                        key={`ingredient-${i.ingredient_name}`}
                        ingredientData={i}
                      />
                    )
                )}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  )
}
export default ConcernItem
