import React from 'react'
import { Experiment, Variant, emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import { store } from '../store/configureStore'
import { setExperimentVariant } from '../actions/app.actions'
import { VERSION } from '../constants/configs/version'
import App from './App'

if (process.env.EXPERIMENT_DEBUGGER) {
  experimentDebugger.setDebuggerAvailable(true)
  experimentDebugger.enable()
}

const EXPERIMENT_NAME = 'App'
const EXPERIMENT_VERSION_NAME = EXPERIMENT_NAME + VERSION

emitter.defineVariants(EXPERIMENT_VERSION_NAME, ['A', 'B'])

// set experiment variant in redux store
emitter.addActiveVariantListener((experimentVersionName, variant) => {
  // remove the version from the experiment name to store it in redux store.
  const experimentName = experimentVersionName.replace(VERSION, '')
  store.dispatch(setExperimentVariant(experimentName, variant))
})

function AppExperiment() {
  return (
    <Experiment name={EXPERIMENT_VERSION_NAME}>
      <Variant name="A">
        <App />
      </Variant>
      <Variant name="B">
        <App />
      </Variant>
      {/*<Variant name="C">
        <App />
      </Variant>*/}
    </Experiment>
  )
}

export default AppExperiment
