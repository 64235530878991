import React from 'react'
import ProvenIcon from '../../../shared/proven-icon'
import ProvenIconWithText from '../../../shared/proven-icon-with-text'
import { signoutUser } from '../../../../actions/auth.actions'

export const sidebarItems = [
  {
    id: 'skincare',
    href: '/account/',
    icon: <ProvenIcon type="tab" name="home" />,
    text: 'Home'
  },
  {
    id: 'profile',
    href: '/account/skin/',
    icon: <ProvenIcon type="tab" name="personalize" color="primary" />,
    text: 'Personalize'
  },
  {
    id: 'subscriptions',
    href: '/account/subscriptions/',
    icon: <ProvenIcon type="tab" name="subscriptions" color="primary" />,
    text: 'Subscriptions'
  },
  {
    id: 'shop',
    href: '/account/shop/',
    icon: <ProvenIcon type="tab" name="shop" color="primary" />,
    text: 'Shop'
  },
  {
    id: 'account',
    href: '/account/settings/info/',
    icon: <ProvenIcon type="tab" name="account" color="primary" />,
    text: 'Account'
  },
  {
    id: 'support',
    href: '/account/support/',
    icon: <ProvenIcon type="tab" name="customer-care" color="primary" />,
    text: 'Customer Care'
  }
]

export const bottomBarItems = [
  {
    id: 'skincare',
    href: '/account/',
    icon: <ProvenIcon type="tab" name="home" color="primary" />,
    text: 'Home'
  },
  {
    id: 'profile',
    href: '/account/skin/',
    icon: <ProvenIcon type="tab" name="personalize" color="primary" />,
    text: 'Personalize'
  },
  {
    id: 'subscriptions',
    href: '/account/subscriptions/',
    icon: <ProvenIcon type="tab" name="subscriptions" color="primary" />,
    text: 'Subscriptions'
  },
  {
    id: 'shop',
    href: '/account/shop/',
    icon: <ProvenIcon type="tab" name="shop" color="primary" />,
    text: 'Shop'
  },
  {
    id: 'more',
    icon: <ProvenIcon type="tab" name="more" color="primary" />,
    text: 'More'
  }
]

export const customerCareItem = {
  label: (
    <ProvenIconWithText type="tab" name="customer-care" color="primary">
      Customer Care
    </ProvenIconWithText>
  ),
  itemName: 'Customer Care',
  link: '/account/support/',
  type: 'link'
}

export const accountSettingsItem = {
  label: (
    <ProvenIconWithText type="tab" name="account" color="primary">
      Account Settings
    </ProvenIconWithText>
  ),
  itemName: 'Account Settings',
  link: '/account/settings/info/',
  type: 'link'
}

export const orderHistoryItem = {
  label: (
    <ProvenIconWithText type="dashboard" name="order-history" color="primary">
      Order History
    </ProvenIconWithText>
  ),
  itemName: 'Order History',
  link: '/account/settings/orders/',
  type: 'link'
}

export const moreProveMenuItems = [
  customerCareItem,
  accountSettingsItem,
  {
    label: (
      <ProvenIconWithText type="dashboard" name="credits-referrals" color="primary">
        Credits & Referrals
      </ProvenIconWithText>
    ),
    itemName: 'Credits & Referrals',
    link: '/account/settings/credits/',
    type: 'link'
  },
  orderHistoryItem,
  {
    link: '/logout/',
    type: 'dispatch',
    action: signoutUser,
    redirect: '/',
    cypressId: 'logout',
    id: 'logout',
    label: (
      <ProvenIconWithText type="tab" name="logout" color="primary">
        Log Out
      </ProvenIconWithText>
    ),
    itemName: 'Log Out'
  }
]
