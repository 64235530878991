import {
  GET_INVOICE_DETAILS_ERROR,
  GET_INVOICE_DETAILS_IN_PROGRESS,
  GET_INVOICE_DETAILS_SUCCESS,
  REQUEST_FAIL,
  SET_CHECKOUT_WARNING,
  START_CHECKING_COUPON_CODE,
  STOP_CHECKING_COUPON_CODE
} from '../constants/actionTypes'
import {
  shippingAddressUrl,
  purchaseUrl,
  previewUrl,
  checkoutUrl,
  subscribeAndSaveUrl,
  invoiceDetailsUrl
} from '../constants/endpoints'
import provenApi from '../services/proven-api'
import provenPay from '../services/proven-pay'
import { pathOr, omit, forEachObjIndexed } from 'ramda'
import {
  clearAccountError,
  setAccountData,
  setAccountError,
  updateAccountBillingAddress,
  updateAccountPaymentMethod,
  updateAccountAndQuizState,
  updateAccountInfo,
  setAccountShippingAddress,
  getUserSubscriptions,
  getOrderHistory
} from './account.actions'
import {
  purchaseOptionsSelector,
  shippingAddressSelector,
  checkoutPayloadSelector,
  accessoriesPayloadSelector,
  orderIdSelector,
  appEditionSelector
} from '../utils/selectors'
import { reset, change } from 'redux-form'
import { trackEvent, identifyUser } from '../utils/analytics'
import { requestSuccess, requestSent, requestFail } from './request.actions'
import {
  getOrderDetailsAction,
  setAccessoriesPurchasePreview,
  setCoupon,
  setStatus
} from './checkout.actions'
import { history } from '../store/configureStore'
import { buildMemoizedCallPostPreview } from '../utils/endpoint-cache'

export const performCheckout =
  ({ chargebeeToken, braintreeNonce, type, shippingAddress, billingAddress }) =>
  (dispatch, getState) => {
    // eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
      dispatch(clearAccountError())

      let trackMessage = `checkout start - shippingAddress`

      let payload = checkoutPayloadSelector(getState())

      // We need to pass mockOrderId instead of mockCheckoutId because
      // Google Analytics uses mockOrderId for tracking purpose
      const checkoutId = orderIdSelector(getState())

      if (shippingAddress) {
        payload.shippingAddress = shippingAddress
      }

      if (billingAddress) {
        payload.billingAddress = billingAddress
      }

      if (chargebeeToken) {
        payload.chargebeeToken = chargebeeToken
        trackMessage += ', chargebeeToken'
      }

      if (braintreeNonce) {
        payload.braintreeNonce = braintreeNonce
        payload.type = type
        trackMessage += ', braintreeNonce'
      }

      if (checkoutId) {
        payload.checkoutId = checkoutId
      }

      trackEvent(trackMessage)

      try {
        const { data } = await provenPay.post(checkoutUrl, payload)
        const appEdition = appEditionSelector(getState())
        if (data.success) {
          const {
            orderCreatedAt,
            customer: { billingAddress },
            card
          } = data
          if (billingAddress) {
            dispatch(updateAccountBillingAddress(billingAddress))
          }
          dispatch(setShippingAddress(payload.shippingAddress))
          dispatch(setAccountShippingAddress(payload.shippingAddress))
          dispatch(updateAccountPaymentMethod(card))
          if (orderCreatedAt) {
            // Update the user account info state with user source immediately after order complete
            dispatch(
              updateAccountInfo({
                orderCreatedAt,
                source: appEdition
              })
            )
          }
          dispatch(getUserSubscriptions()) // sync-up active subscriptions in redux
          dispatch(getOrderHistory())
        }
        identifyUser()
        resolve()
      } catch (error) {
        dispatch(setCheckoutError({ response: error }))
        reject(error.response.data.message)
      }
    })
  }

export const subscribeAndSave =
  ({ shippingAddress }) =>
  dispatch => {
    // eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
      await provenPay
        .post(subscribeAndSaveUrl, { shippingAddress })
        .then(() => {
          return dispatch(getUserSubscriptions()) // sync-up active subscriptions in redux
        })
        .catch(error => {
          dispatch(setCheckoutError({ response: error }))
          reject(error.data.error)
        })

      resolve()
    })
  }

export const updateShippingAddress = () => async (dispatch, getState) => {
  dispatch(clearAccountError())

  let trackMessage = `patch order details start - shippingAddress`
  const shippingAddress = shippingAddressSelector(getState())

  trackEvent(trackMessage)
  try {
    await provenPay.put(shippingAddressUrl, { shippingAddress })
  } catch (error) {
    const reason =
      'Sorry, there was an issue with your shipping address. Please check and try again.'
    trackEvent('Update shipping address Error', {
      reason
    })

    dispatch(setAccountError(reason))
    throw error
  }
}

// TODO deprecate this method
export const submitPurchase = () => async (dispatch, getState) => {
  dispatch(setStatus({ loading: 'Finalizing order' }))
  const state = getState()
  const options = purchaseOptionsSelector(state)

  try {
    const { data } = await provenApi.post(purchaseUrl, omit(['state'], options))

    // TODO: shouldn't keep the result object in account state
    dispatch(setAccountData(data.result))

    dispatch(updateAccountAndQuizState(data))
    dispatch(getPurchaseOrderDetails(options))
  } catch (error) {
    dispatch(setError(error))
  }
}

export const getPurchaseOrderDetails = options => async dispatch => {
  const _options = { ...options }
  delete _options.subscribe
  dispatch(setStatus({ loading: 'Getting order details' }))
  try {
    // eslint-disable-next-line no-console
    console.log(`PURCHASE ACTIONS - getOrderDetails _options: `, _options)
    const {
      data: {
        result: { summary }
      }
    } = await memoizedCallPostPreview(_options)
    dispatch(getOrderDetailsAction(summary))
    trackEvent('Purchase Success')
    history.push('/order/success')
    dispatch(setStatus(null))
  } catch (error) {
    dispatch(setError(error))
  }
}

const callPostPurchasePreview = async options => {
  return await provenPay.post(previewUrl, options)
}

const memoizedCallPostPreview = buildMemoizedCallPostPreview(callPostPurchasePreview)

export const purchasePreview = async options => {
  const { data } = await memoizedCallPostPreview(options)
  return data
}

export const previewOrderDetails = options => async dispatch => {
  if (options?.items?.length === 0) return // do nothing if no items

  const { successCallback, errorCallback, ...purchasePreviewOptions } = options
  dispatch(requestSent(previewUrl))
  try {
    dispatch({ type: START_CHECKING_COUPON_CODE })
    const data = await purchasePreview(purchasePreviewOptions)
    dispatch(requestSuccess(previewUrl))
    dispatch(getOrderDetailsAction(data))
    if (!data.isCouponValid) {
      dispatch(setCoupon(null))
    }
    if (successCallback) {
      successCallback()
    }
    return data
  } catch (error) {
    dispatch({ type: REQUEST_FAIL, payload: { error: error?.response?.data } })
    if (errorCallback) {
      errorCallback(error?.response?.data?.message || 'Bad Request')
    }
    throw error
  } finally {
    dispatch({ type: STOP_CHECKING_COUPON_CODE })
  }
}

export const previewAccessoriesPurchase = () => (dispatch, getState) => {
  const payload = accessoriesPayloadSelector(getState())
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    dispatch(requestSent(previewUrl))
    try {
      const data = await purchasePreview(payload)
      dispatch(requestSuccess(previewUrl))
      dispatch(setAccessoriesPurchasePreview(data))
      resolve(data)
    } catch (error) {
      dispatch(requestFail(previewUrl))
      reject(error)
    }
  })
}

const setCheckoutError = error => dispatch => {
  const errorObject = error.response?.response?.data
  let reason = ''

  if (errorObject.message === 'SHIPPING_ADDRESS_INVALID') {
    reason = 'Sorry, there was an issue with your shipping address. Please check and try again.'
  } else {
    reason = errorObject.message
  }

  trackEvent('Update shipping address Error', {
    reason
  })

  dispatch(setAccountError(reason))
}

const setError = error => dispatch => {
  const errorObject = error.response.data
  let reason = ''

  if (errorObject.message === 'SHIPPING_ADDRESS_INVALID') {
    reason = 'Sorry, there was an issue with your shipping address. Please check and try again.'
  } else if (errorObject.message === 'STRIPE_ERROR') {
    reason = errorObject.reason
  } else {
    reason = errorObject.error
  }

  trackEvent('Purchase Error', {
    reason
  })

  dispatch(
    setStatus({
      error: reason
    })
  )
}

export const setCheckoutWarning = warning => dispatch => {
  dispatch({
    type: SET_CHECKOUT_WARNING,
    warning
  })
}

export const setBillingAddress = billingInfo => (dispatch, getState) => {
  dispatch(reset('billing'))
  const { sameAsShipping } = billingInfo

  if (sameAsShipping) {
    setBillingAddressSameAsShippingAddress()
  } else {
    const { billingAddress } = billingInfo
    forEachObjIndexed((value, key) => dispatch(change('billing', key, value)), billingAddress)
  }
}

export const setShippingAddress = shippingAddress => dispatch => {
  dispatch(reset('shipping'))
  forEachObjIndexed((value, key) => dispatch(change('shipping', key, value)), shippingAddress)
}

export const resetBillingFields = () => dispatch => {
  dispatch(reset('billing'))
}

export const setBillingAddressSameAsShippingAddress = () => (dispatch, getState) => {
  dispatch(reset('billing'))
  const shippingAddress = pathOr({}, ['form', 'shipping', 'values'], getState())
  forEachObjIndexed((value, key) => dispatch(change('billing', key, value)), shippingAddress)
}

export const getInvoiceDetails = () => async dispatch => {
  try {
    dispatch({
      type: GET_INVOICE_DETAILS_IN_PROGRESS
    })
    const { data } = await provenPay.get(invoiceDetailsUrl)
    dispatch({
      type: GET_INVOICE_DETAILS_SUCCESS,
      payload: { invoiceDetails: data }
    })
    return data
  } catch (error) {
    console.error(`Error on ${invoiceDetailsUrl} response`, error)
    dispatch({
      type: GET_INVOICE_DETAILS_ERROR,
      payload: { error }
    })
  }
}
