import { REQUEST_FAIL, REQUEST_SUCCESS, REQUEST_SENT } from '../constants/actionTypes'

// ToDo
// We are not doing anyting with these actions, we should remove them or add some logic
export const requestSent = action => ({
  type: REQUEST_SENT,
  action
})

export const requestSuccess = action => ({
  type: REQUEST_SUCCESS,
  action
})

export const requestFail = action => ({
  type: REQUEST_FAIL,
  action
})
