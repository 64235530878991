import React from 'react'
import propTypes from 'prop-types'

import './styles.scss'

const Ingredient = ({ title, subTitle, image, description, hexagonColor }) => {
  return (
    <div styleName="ingredients-wrapper">
      <div styleName="image">
        <div styleName="img-background">
          <svg
            width="81"
            height="90"
            viewBox="0 0 81 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M40.1641 0L80.1641 19.6875V70.3125L40.1641 90L0.164062 70.3125V19.6875L40.1641 0Z"
              fill={hexagonColor}
            />
          </svg>
        </div>
        <div styleName="img-front">
          <img src={`${image}`} alt={title} />
        </div>
      </div>
      <div styleName="title">{title}</div>
      <div styleName="title">{subTitle}</div>
      {description && <div styleName="description">{description}</div>}
    </div>
  )
}

Ingredient.defaultProps = {
  hexagonColor: '#D6EBF2'
}

Ingredient.propTypes = {
  title: propTypes.string,
  subTitle: propTypes.string,
  image: propTypes.string,
  description: propTypes.string,
  hexagonColor: propTypes.string
}

export default Ingredient
