import { pathOr } from 'ramda'
import {
  CART_INCREASE_QTY,
  CART_DECREASE_QTY,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_CLEAR,
  CART_CHANGE_QTY,
  CART_OPEN,
  CART_CLOSE,
  CART_ADD_ACCESSORY_ITEM,
  CART_REMOVE_ACCESSORY_ITEM,
  SET_ORDERED_SUBSCRIBABLE_PRICE_IDS,
  CART_UPDATE_ITEM
} from '../constants/actionTypes'
import { previewAccessoriesPurchase, previewOrderDetails } from './purchase.actions'
import { updateAccountInfo } from './account.actions'
import { trackProductAdded } from '../utils/analytics'
import {
  getDefaultSystemSubscriptionPrice,
  getSystemProduct,
  getSystemInCart,
  findCartProductWithPriceId,
  findPriceInProduct,
  formatPriceAsNumber
} from 'utils/helpers'
import {
  purchasePreviewPayloadSelector,
  skipTheLineInCartSelector,
  combinedProductsSelector
} from '../utils/selectors'
import {
  findPriceInProducts,
  getDefaultEyeCreamDuoSubscriptionPrice,
  getEyeCreamDuoInCart,
  getEyeCreamDuoProduct,
  getProductById,
  getSerumInCart,
  getSerumProduct,
  getShopProductInCart,
  isOneTimeProduct
} from '../utils/helpers'
import { EYE_CREAM_PRODUCT, SYSTEM_PRODUCT } from '../constants/products'
import { setCoupon } from './checkout.actions'

export const getPreviewDetails =
  ({ shippingAddress, billingAddress, coupons, successCallback, errorCallback } = {}) =>
  (dispatch, getState) => {
    const payload = purchasePreviewPayloadSelector(getState())
    if (shippingAddress) {
      payload.shippingAddress = shippingAddress
    }

    if (billingAddress) {
      payload.billingAddress = billingAddress
    }

    if (coupons && coupons.length > 0) {
      payload.coupons = coupons
    }

    if (successCallback) {
      payload.successCallback = successCallback
    }

    if (errorCallback) {
      payload.errorCallback = errorCallback
    }

    return dispatch(previewOrderDetails(payload))
  }

export const changeCartProductQty =
  (priceId, qty, shouldCallPreview = true) =>
  dispatch => {
    dispatch({
      type: CART_CHANGE_QTY,
      priceId,
      qty
    })
    if (shouldCallPreview) {
      dispatch(getPreviewDetails())
    }
  }

export const putCartProductQty =
  (priceId, { qty, period }, shouldCallPreview = true) =>
  dispatch => {
    dispatch({
      type: CART_UPDATE_ITEM,
      priceId,
      cartItem: { qty, period }
    })
    if (shouldCallPreview) dispatch(getPreviewDetails())
  }

export const increaseCartProductQty =
  (priceId, shouldCallPreview = true) =>
  dispatch => {
    dispatch({
      type: CART_INCREASE_QTY,
      priceId
    })
    if (shouldCallPreview) {
      dispatch(getPreviewDetails())
    }
  }

export const decreaseCartProductQty =
  (priceId, shouldCallPreview = true) =>
  (dispatch, getState) => {
    const cartItems = pathOr({}, ['cart', 'items'], getState())
    const { qty } = cartItems[priceId]
    if (qty === 1) {
      // remove item from cart
      dispatch(removeItemFromCart(priceId))
    } else {
      // decrease product quantity
      dispatch({
        type: CART_DECREASE_QTY,
        priceId
      })
      if (shouldCallPreview) {
        dispatch(getPreviewDetails())
      }
    }
  }

const preventAddingDuplicateItemsToCart = (context, next, getItemInCart, productId) => {
  if (!context.priceItem.id.startsWith(productId)) {
    next()
    return
  }

  const { products, cartItems } = context
  const { price } = getItemInCart({ products, cartItems })

  if (price) context.priceItem = null
  else next()
}

// Apply rules for cart operations using a middleware pattern.
const cartPolicies = {
  add: [
    (context, next) =>
      preventAddingDuplicateItemsToCart(context, next, getEyeCreamDuoInCart, EYE_CREAM_PRODUCT),
    (context, next) =>
      preventAddingDuplicateItemsToCart(context, next, getSystemInCart, SYSTEM_PRODUCT)
  ]
}

const evaluateCartPolicies = async (policySet, context) => {
  let prevIndex = -1
  const policies = cartPolicies[policySet]

  const runner = async index => {
    if (index === prevIndex) {
      throw new Error('next() called multiple times')
    }

    prevIndex = index

    const policy = policies[index]

    if (policy) {
      await policy(context, () => runner(index + 1))
    }
  }

  await runner(0)
}

export const addItemToCartNG =
  ({
    ctaText,
    priceItem: initialPriceItem,
    qty: initialQty = 1,
    shouldTrackProductAdded = true,
    shouldCallPreview = true,
    disableModeSwitch = undefined
  }) =>
  (dispatch, getState) => {
    if (!initialPriceItem) throw new Error('priceItem is mandatory')

    const {
      cart: { items: cartItems }
    } = getState()
    const products = combinedProductsSelector(getState())

    const policyContext = {
      qty: initialQty,
      priceItem: initialPriceItem,
      products,
      cartItems
    }

    evaluateCartPolicies('add', policyContext)

    const { qty, priceItem } = policyContext

    if (priceItem && qty) {
      dispatch({
        type: CART_ADD_ITEM,
        priceItem,
        qty,
        disableModeSwitch
      })

      const product = findCartProductWithPriceId({
        products,
        priceId: priceItem.id
      })
      const { price, oneTime } = findPriceInProduct({
        product,
        priceId: priceItem.id
      })

      const coupon = pathOr(null, ['checkout', 'coupon'], getState())
      const variant = pathOr('A', ['app', 'experiments', 'App'], getState())
      const cartId = pathOr('', ['checkout', 'mockCartId'], getState())
      const account = pathOr({}, ['account'], getState())
      const userInfo = pathOr({}, ['account', 'info'], getState())
      const toggleTouched = userInfo?.isSubscriptionSelected !== undefined
      const isHomePage =
        window &&
        window.location &&
        window.location.pathname &&
        (window.location.pathname === '/' ||
          window.location.pathname === '/personalized-subscription')
      if (priceItem.id.startsWith(SYSTEM_PRODUCT) && !userInfo.hasPurchased) {
        // Default isOneTimeSystemPurchase to false upon add to cart
        if (isHomePage) {
          dispatch(
            updateAccountInfo({
              isOneTimeSystemPurchase: toggleTouched ? !userInfo?.isSubscriptionSelected : false
            })
          )
        } else {
          dispatch(updateAccountInfo({ isOneTimeSystemPurchase: false }))
        }
      }
      if (shouldTrackProductAdded) {
        trackProductAdded({
          product,
          name: price.name,
          ctaText,
          oneTime,
          qty,
          price: formatPriceAsNumber(price.price),
          coupon,
          variant,
          cartId,
          account
        })
      }
      if (shouldCallPreview) {
        dispatch(getPreviewDetails())
      }
    }
  }

// TODO: possibly refactor to use addItemToCartNG
export const addItemToCart =
  ({
    ctaText,
    priceItem,
    qty = 1,
    shouldTrackProductAdded = true,
    shouldCallPreview = true,
    disableModeSwitch = undefined
  }) =>
  (dispatch, getState) => {
    if (!priceItem) throw new Error('priceItem is mandatory')

    dispatch({
      type: CART_ADD_ITEM,
      priceItem,
      qty,
      disableModeSwitch
    })

    const allProducts = combinedProductsSelector(getState())

    const product = findCartProductWithPriceId({
      products: allProducts,
      priceId: priceItem.id
    })
    const { price, oneTime } = findPriceInProduct({
      product,
      priceId: priceItem.id
    })

    const coupon = pathOr(null, ['checkout', 'coupon'], getState())
    const variant = pathOr('A', ['app', 'experiments', 'App'], getState())
    const cartId = pathOr('', ['checkout', 'mockCartId'], getState())
    const account = pathOr({}, ['account'], getState())
    const userInfo = pathOr({}, ['account', 'info'], getState())
    if (priceItem.id.startsWith(SYSTEM_PRODUCT) && !userInfo.hasPurchased) {
      // Default isOneTimeSystemPurchase to false upon add to cart
      dispatch(updateAccountInfo({ isOneTimeSystemPurchase: false }))
    }
    if (shouldTrackProductAdded) {
      trackProductAdded({
        product,
        name: price.name,
        ctaText,
        oneTime,
        qty,
        price: formatPriceAsNumber(price.price),
        coupon,
        variant,
        cartId,
        account
      })
    }
    if (shouldCallPreview) {
      dispatch(getPreviewDetails())
    }
  }

export const toggleCartSubscription =
  (shouldCallPreview = true) =>
  (dispatch, getState) => {
    const {
      cart: { items: cartItems },
      product: { items: products }
    } = getState()
    // remove existing system from cart
    const { price, oneTime } = getSystemInCart({ products, cartItems, isSystemOnly: true })
    dispatch(removeItemFromCart(price.id, false))
    // add a new system price to cart
    const systemProduct = getSystemProduct(products)
    const newPriceItem = oneTime
      ? getDefaultSystemSubscriptionPrice(systemProduct)
      : systemProduct.one_time_price
    return dispatch(addItemToCart({ priceItem: newPriceItem, shouldCallPreview }))
  }

/*const autoApplyCoupon = (productId, oneTime, dispatch) => {
  if (productId === EYE_CREAM_PRODUCT) {
    const isSwitchingToSubscription = oneTime
    const now = Date.now()
    const automateApplyCouponCode = Object.keys(EYE_CREAM_CAMPAIGNS).find(couponCode => {
      const startDate = new Date(EYE_CREAM_CAMPAIGNS[couponCode].startDate)
      const endDate = new Date(EYE_CREAM_CAMPAIGNS[couponCode].endDate)
      return startDate.getTime() < now && now < endDate.getTime()
    })
    const shouldAutomaticApplyCoupon = Boolean(automateApplyCouponCode)

    if (shouldAutomaticApplyCoupon && isSwitchingToSubscription) {
      dispatch(setCoupon(automateApplyCouponCode))
    } else {
      dispatch(setCoupon())
    }
  }
}*/

/**
 * get default subscription price for each set of products with subscription
 * @param productId
 * @param product
 * @returns {*}
 */
const getDefaultSubscriptionPrice = (productId, product) => {
  if (productId === EYE_CREAM_PRODUCT) {
    return getDefaultEyeCreamDuoSubscriptionPrice(product)
  } else {
    return getDefaultSystemSubscriptionPrice(product)
  }
}

/**
 * this method toggle between subscription and one-time price
 * for all set of products with subscription (proven-system and eye-cream-duo)
 * @param productId
 * @param priceId
 * @returns {function(*, *): *}
 */
export const toggleShopCartSubscription =
  ({ productId, priceId, isOneTimeSystemPurchase }, shouldCallPreview = true) =>
  (dispatch, getState) => {
    const {
      product: { items: products }
    } = getState()
    // remove current product price from the cart
    const { price, oneTime } = findPriceInProducts({ products, priceId })
    dispatch(removeItemFromCart(price.id, false))
    dispatch(updateAccountInfo({ isOneTimeSystemPurchase: isOneTimeSystemPurchase }))
    // add a new product price to cart
    const product = getProductById(products, productId)
    const newPriceItem = oneTime
      ? getDefaultSubscriptionPrice(productId, product)
      : product.one_time_price
    dispatch(addItemToCart({ priceItem: newPriceItem, shouldCallPreview }))
    return dispatch(updateAccountInfo({ isOneTimeSystemPurchase: isOneTimeSystemPurchase }))
  }

export const removeSkipTheLineFromCart =
  (shouldCallPreview = true) =>
  (dispatch, getState) => {
    const { isSkipTheLineInCart, skipTheLineProduct } = skipTheLineInCartSelector(getState())
    if (skipTheLineProduct && isSkipTheLineInCart) {
      dispatch(removeItemFromCart(skipTheLineProduct.one_time_price.id, shouldCallPreview))
    }
  }

export const addSkipTheLineToCart =
  (shouldCallPreview = true) =>
  (dispatch, getState) => {
    const { isSkipTheLineInCart, skipTheLineProduct } = skipTheLineInCartSelector(getState())
    if (!isSkipTheLineInCart) {
      dispatch(
        addItemToCart({
          priceItem: skipTheLineProduct.one_time_price,
          qty: 1,
          shouldTrackProductAdded: false,
          shouldCallPreview
        })
      )
    }
  }

// TODO: Refactor by replacing references to addSystemToCart with:
//
//   1. const price = usePrice({ strategy: 'defaultPrice', query: { productId: SYSTEM_PRODUCT } })
//   2. addItemToCart({ priceItem: price, ctaText: ... })
//
// Note: usePrice has a defaultPrice strategy that gets the correct price item for the system product.
// Note: cartPolicies.add has a policy that prevents a duplicate system from being added to the cart.
export const addSystemToCart =
  ({
    ctaText = 'get my system',
    shouldTrackProductAdded = true,
    shouldCallPreview = true,
    oneTime = false
  } = {}) =>
  (dispatch, getState) => {
    const {
      cart: { items: cartItems }
    } = getState()

    const products = combinedProductsSelector(getState())

    const { price } = getSystemInCart({ products, cartItems, isSystemOnly: true })
    if (price?.id.startsWith('proven-system')) return // system product is already in the cart

    const systemProduct = getSystemProduct(products)
    const priceItem = oneTime
      ? systemProduct.one_time_price
      : getDefaultSystemSubscriptionPrice(systemProduct)
    return dispatch(
      addItemToCart({ ctaText, priceItem, shouldTrackProductAdded, shouldCallPreview })
    )
  }

export const removeItemFromCart =
  (priceId, getPreview = true) =>
  dispatch => {
    const isSystem = priceId.startsWith(SYSTEM_PRODUCT)
    // Reset isOneTimeSystemPurchase
    isSystem && dispatch(updateAccountInfo({ isOneTimeSystemPurchase: null }))
    dispatch(removeItem(priceId))
    if (getPreview) {
      dispatch(getPreviewDetails())
    }
  }

export const clearCart = () => dispatch => dispatch({ type: CART_CLEAR })

export const removeItem = priceId => ({
  type: CART_REMOVE_ITEM,
  priceId
})

export const openCart = () => ({
  type: CART_OPEN
})

export const closeCart = () => ({
  type: CART_CLOSE
})

export const addAccessoryItem =
  (priceId, shouldCallPreview = true) =>
  dispatch => {
    dispatch({
      type: CART_ADD_ACCESSORY_ITEM,
      priceId
    })
    if (shouldCallPreview) {
      dispatch(previewAccessoriesPurchase())
    }
  }

export const removeAccessoryItem =
  (priceId, shouldCallPreview = true) =>
  dispatch => {
    dispatch({
      type: CART_REMOVE_ACCESSORY_ITEM,
      priceId
    })
    if (shouldCallPreview) {
      dispatch(previewAccessoriesPurchase())
    }
  }

// TODO: Refactor by replacing references to addSystemToCart with:
//
//   1. const price = usePrice({ strategy: 'defaultPrice', query: { productId: EYE_CREAM_PRODUCT } })
//   2. addItemToCart({ priceItem: price, ctaText: ... })
//
// Note: usePrice has a defaultPrice strategy that gets the correct price item for the Eye Cream Duo product.
// Note: cartPolicies.add has a policy that prevents a duplicate Eye Cream Duo from being added to the cart.
export const addEyeCreamDuoToCart =
  (ctaText = 'get Eye Cream Duo', shouldTrackProductAdded = true, shouldCallPreview = true) =>
  (dispatch, getState) => {
    const {
      cart: { items: cartItems }
    } = getState()

    const products = combinedProductsSelector(getState())

    const { price } = getEyeCreamDuoInCart({ products, cartItems })
    if (price) return // eye cream duo product is already in the cart

    const eyeCreamDuoProduct = getEyeCreamDuoProduct(products)
    const priceItem = getDefaultEyeCreamDuoSubscriptionPrice(eyeCreamDuoProduct)
    return dispatch(
      addItemToCart({ ctaText, priceItem, shouldTrackProductAdded, shouldCallPreview })
    )
  }

export const checkCoupon = couponCode => async dispatch => {
  try {
    dispatch(setCoupon(couponCode))
    return await dispatch(getPreviewDetails({})) // in getOrderDetailsAction action it is passed tha response value of isCouponValid field, and corresponding reducer saves it
  } catch (e) {
    console.error(e.message)
  }
}

export const setOrderedSubscribablePriceIds = orderedSubscribablePriceIds => ({
  type: SET_ORDERED_SUBSCRIBABLE_PRICE_IDS,
  orderedSubscribablePriceIds
})

// TODO: Refactor by replacing references to addSystemToCart with:
//
//   1. const price = usePrice({ strategy: 'defaultPrice', query: { productId: EYE_CREAM_PRODUCT } })
//   2. addItemToCart({ priceItem: price, ctaText: ... })
//
// Note: usePrice has a defaultPrice strategy that gets the correct price item for the Eye Cream Duo product.
// Note: cartPolicies.add has a policy that prevents a duplicate Eye Cream Duo from being added to the cart.
export const addSerumToCart =
  (
    ctaText = 'get Serum',
    shouldTrackProductAdded = true,
    shouldCallPreview = true,
    oneTime = false
  ) =>
  (dispatch, getState) => {
    const {
      cart: { items: cartItems }
    } = getState()

    const products = combinedProductsSelector(getState())

    const { price } = getSerumInCart({ products, cartItems })
    if (price) return // eye cream duo product is already in the cart

    const serumProduct = getSerumProduct(products)
    const priceItem = oneTime
      ? serumProduct.one_time_price
      : getDefaultSystemSubscriptionPrice(serumProduct)

    return dispatch(
      addItemToCart({ ctaText, priceItem, shouldTrackProductAdded, shouldCallPreview })
    )
  }

/**
 * This method increase element to the cart
 * @param priceItem
 * @param ctaText
 * @param disableModeSwitch: is a flag that disable user to switch between on-time or subscription mode
 */
const handleIncreaseProductToCart = (priceItem, shouldCallPreview) => dispatch => {
  if (!priceItem) return

  dispatch(increaseCartProductQty(priceItem?.id, shouldCallPreview))
}

/**
 * This method add a NEW elemento to the cart
 * @param priceItem
 * @param ctaText
 * @param disableModeSwitch: is a flag that disable user to switch between on-time or subscription mode
 * @param shouldCallPreview: if it is false, preview will not be called
 */
const handleAddNewProductToCart =
  (priceItem, ctaText, disableModeSwitch, shouldCallPreview) => async (dispatch, getState) => {
    const {
      cart: { items: cartItems },
      shop: { shopProducts }
    } = getState()

    if (!priceItem) return

    if (priceItem.id === EYE_CREAM_PRODUCT) {
      const { price } = getShopProductInCart({
        productId: EYE_CREAM_PRODUCT,
        shopProducts,
        shopCartItems: cartItems
      })

      if (price) {
        await dispatch(removeItemFromCart(price.id, false))
      }
    }

    dispatch(addItemToCart({ ctaText, priceItem, disableModeSwitch, shouldCallPreview }))
  }

/**
 *
 * @param priceItem
 * @param ctaText
 * @param disableModeSwitch: is a flag that disable user to switch between on-time or subscription mode
 */
export const addProductToCart = (priceItem, ctaText, disableModeSwitch) => (dispatch, getState) => {
  const {
    cart: { items: cartItems },
    shop: { shopProducts }
  } = getState()

  if (!priceItem) return

  const priceId = priceItem?.id

  if (isOneTimeProduct(priceId, shopProducts) && cartItems[priceId]) {
    // is a one-time product and has not been added to your cart.
    return dispatch(handleIncreaseProductToCart(priceItem, false)) //we dont call preview, since it will be called when cart is opened
  } else {
    // it is a subscription, or new item in the cart
    return dispatch(handleAddNewProductToCart(priceItem, ctaText, disableModeSwitch, false)) //we dont call preview, since it will be called when cart is opened
  }
}
