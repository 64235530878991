import React, { useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack, Typography } from '@mui/material'
import { BUILDER_CDN } from '../../../../../../constants/endpoints'
import { createProvenTheme } from '../../../../../../styles/theme-proven'
import AccordionItem from './accordionItem'
import { DesktopIngredient } from './ingredient'
import { Image } from 'components/builder/blocks/shared/Image'
import { PUBLIC_SYSTEM_PRODUCT_URL } from '../../../../../../constants/products'

const theme = createProvenTheme()
const spacing = theme.spacing

const LEFT_SIDE_CONCERN_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/821d475bd1a940da95c3cd5cd58a06fa`
const RIGHT_SIDE_CONCERN_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/f7ffe126b88c4095b35a3388b2df8bb0`
const COMMON_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/ae0500bad7c441ec821655fe4b48fc8c`

function AccordionConcerns({ skin, skinConcernsDescription }) {
  const isSystemProductPage = useRouteMatch('/account/shop/skin')
  const isPublicSystemProductPage = useRouteMatch(PUBLIC_SYSTEM_PRODUCT_URL)
  const isCongratulationsPage = useRouteMatch('/account/congratulations')
  const isShopPage = useRouteMatch('/account/shop')
  const [selectedConcernIndex, setSelectedConcernIndex] = useState(0)
  const isLgUp = useMediaQuery(th => th.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(th => th.breakpoints.up('md'))

  const concerns = useMemo(() => {
    return skin.filter(
      c =>
        c.body &&
        c.vital_title &&
        ((c.ingredients && c.ingredients.length) ||
          (c.genome_clinical_percent_1 &&
            c.genome_clinical_text_1 &&
            c.genome_clinical_percent_2 &&
            c.genome_clinical_text_2))
    )
  }, [skin])

  const selectedConcern = concerns[selectedConcernIndex]

  const getConcernBackgroundImgUrl = position => {
    if (!isLgUp) return COMMON_BACKGROUND_IMG_URL
    if (position === 0) {
      //there is not a background image for first ConcernItem on desktop
      return undefined
    }
    return `${
      position % 2 ? LEFT_SIDE_CONCERN_BACKGROUND_IMG_URL : RIGHT_SIDE_CONCERN_BACKGROUND_IMG_URL
    }`
  }

  return (
    <Box position="relative">
      {isMdUp && (
        <Box
          sx={{
            mx: { xs: -2, md: -8, lg: '-150px', xxl: '-10%' },
            position: { md: 'absolute' },
            right: { md: 0 },
            height: { md: spacing(143.375) },
            width: { md: spacing(104) },
            boxShadow: { md: 'unset' },
            borderRadius: { md: 'unset' }
          }}
        >
          <Image
            lazy
            image={COMMON_BACKGROUND_IMG_URL}
            backgroundPosition="top right"
            aspectRatio={0.7254}
          />
        </Box>
      )}
      <Box
        pt={12}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' },
          gridTemplateRows: 'auto auto auto',
          gridTemplateAreas: {
            xs: `"title title title title"
                 "description description description description"
                 "concerns concerns concerns concerns"`,
            md: `"title title title title title title title title title title title title"
                 "description description description description description description description description description description description description"
                 "concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns"`,
            lg: `"title title title title title . . . . . . ."
                 "description description description description description description . . . . . ."
                 "concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns"`
          }
        }}
      >
        <Typography zIndex={4} sx={{ gridArea: 'title', pb: { xs: 1, lg: 2 } }} variant="h2">
          Based on Your
          <br />
          <Typography component="span" variant="inherit" color="primary.main">
            {'Skin Concerns'}
          </Typography>
        </Typography>
        <Typography
          zIndex={4}
          sx={{ gridArea: 'description', pb: { xs: 8, lg: 10.5 } }}
          variant="body2"
          color="gray.blueGray"
        >
          {skinConcernsDescription && skinConcernsDescription?.trim()
            ? skinConcernsDescription
            : `Your 3-Step System is tailor-made to your skin’s needs. Powerhouse ingredients are carefully chosen to deliver the best results for you based on your unique skin concerns.`}
        </Typography>

        <Box gridArea="concerns">
          <Typography
            zIndex={4}
            variant="title1"
            color="gray.blueGray"
            sx={{ pb: { xs: 3, lg: 2 }, display: 'block' }}
          >
            {isSystemProductPage?.isExact ||
            (!isShopPage && (isPublicSystemProductPage || isCongratulationsPage))
              ? 'Your system addresses'
              : 'YOUR SKINCARE ADDRESSES'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              columnGap: { md: 0, lg: '140px' }
            }}
          >
            <Box sx={{ flex: 1, width: '100%' }}>
              {concerns.map((c, index) => (
                <AccordionItem
                  key={`concern-${c.vital_title}`}
                  {...c}
                  title={c.vital_title}
                  backgroundImageUrl={getConcernBackgroundImgUrl(index)}
                  percentage={c.genome_clinical_percent}
                  percentageDescription={c.genome_clinical_text}
                  description={c.body}
                  open={index === selectedConcernIndex}
                  handleSelectConcern={() => setSelectedConcernIndex(index)}
                  handleCloseAccordion={() => setSelectedConcernIndex(null)}
                  ingredients={c.ingredients}
                  beforeImage={c.genome_img_before}
                  afterImage={c.genome_img_after}
                  sourceLabel={c.genome_img_source}
                />
              ))}
            </Box>
            {isMdUp && (
              <Box sx={{ flex: 1 }}>
                <Stack
                  sx={{
                    mt: { xs: 0, md: -5, lg: -9 },
                    px: { /*md: 16,*/ md: 'unset' },
                    alignSelf: { md: 'start' }
                  }}
                >
                  {(selectedConcern?.ingredients || []).slice(0, 3).map((i, index) => (
                    <DesktopIngredient
                      key={`ingredient-${i.ingredient_name}`}
                      isMdUp={isMdUp}
                      areIngredientsOnTheLeftSide={false}
                      position={index}
                      ingredientData={i}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccordionConcerns
