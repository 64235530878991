import provenPay from '../services/proven-pay'
import { productsUrl } from '../constants/endpoints'
import { REQUEST_SENT, REQUEST_SUCCESS, REQUEST_FAIL, PRODUCT_SYNC } from '../constants/actionTypes'
import { loadShopProducts } from './shop.actions'

export const saveProducts = products => dispatch =>
  dispatch({
    type: PRODUCT_SYNC,
    items: products
  })

export const syncProducts = () => async (dispatch, getState) => {
  const state = getState()
  const isAdmin = state.account?.info?.isAdmin ?? false
  dispatch({ type: REQUEST_SENT, url: productsUrl })
  try {
    //TODO unify shop products and product items on ENG-901
    // and make sure that the endpoint we use doesnt require currency query param, and it returns the prices in user currency (based on db)
    // if we use shop products endpoint it will need to be modified to return all products (including skip the line )
    dispatch(loadShopProducts())
    const products = isAdmin ? await getAllProducts() : await getCurrentProducts()
    dispatch({ type: REQUEST_SUCCESS, url: productsUrl })
    dispatch(saveProducts(products))
    return products
  } catch (error) {
    dispatch({ type: REQUEST_FAIL, url: productsUrl, message: error.message })
    throw error
  }
}

const getAllProducts = async () => {
  const { data } = await provenPay.get(productsUrl)
  return data
}

export const getCurrentProducts = async () => {
  const { data } = await provenPay.get(`${productsUrl}?version=current`)
  return data
}
