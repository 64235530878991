/* eslint no-console: "off" */
import {
  SIGNUP_PASSWORD_SUCCESS,
  SIGNIN_PASSWORD_AUTH_VERFICIATION,
  SIGNIN_PASSWORD_SUCCESS,
  SIGNUP_PASSWORD_AUTH_VERFICIATION,
  SIGNUP_PASSWORD_FAIL,
  SUCCESS_PASSWORDLESS_AUTH,
  FAIL_PASSWORDLESS_AUTH,
  CLEAR_PASSWORDLESS_AUTH
} from '../constants/actionTypes'
import provenApi from '../services/proven-api'
import { Auth as AWSAuth } from 'aws-amplify'
import { checkUserExistsUrl } from '../constants/endpoints'

export const checkValidUser = async (username, preventCreation) => {
  const { data } = await provenApi.get(checkUserExistsUrl, {
    params: { email: encodeURIComponent(username), preventCreation }
  })
  return data
}

export const verifyPasswordAuth = (username, password) => async dispatch => {
  dispatch({ type: SIGNIN_PASSWORD_AUTH_VERFICIATION })
  const response = await AWSAuth.signIn(username, password)
  dispatch({ type: SIGNIN_PASSWORD_SUCCESS })
  return response
}

export const signupPasswordAuth = (username, password, attributes) => async dispatch => {
  dispatch({ type: SIGNUP_PASSWORD_AUTH_VERFICIATION })
  try {
    const signupResult = await AWSAuth.signUp({ username, password, attributes })
    dispatch({ type: SIGNUP_PASSWORD_SUCCESS })
    return signupResult
  } catch (reason) {
    dispatch({ type: SIGNUP_PASSWORD_FAIL, reason })
  }
}

export const signinSuccess = () => ({ type: SIGNIN_PASSWORD_SUCCESS })
export const signinSuccessPasswordless = () => ({
  type: SUCCESS_PASSWORDLESS_AUTH
})
export const signinFailedPasswordless = error => ({
  type: FAIL_PASSWORDLESS_AUTH,
  data: error
})
export const clearPasswordlessAuth = () => ({ type: CLEAR_PASSWORDLESS_AUTH })
