import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ProvenHexShape from '../../../../../../../shared/proven-hex-shape'
import { createProvenTheme } from '../../../../../../../../styles/theme-proven'
import { formatPercentage } from '../../../../../../../../utils/helpers'

const theme = createProvenTheme()
const spacing = theme.spacing

const DESKTOP_HEX_SHAPE_SCALE_BY_POSITION = [1.323, 1.323, 1]

const DESKTOP_IMAGE_MAX_WIDTH_BY_POSITION = [spacing(14), spacing(14.25), spacing(12.695)]

const isEven = number => !(number % 2)

export function DesktopIngredient({ areIngredientsOnTheLeftSide, position, ingredientData }) {
  const hexShapeScaleToUse = DESKTOP_HEX_SHAPE_SCALE_BY_POSITION[position]

  const isIngredientOnTheLeftSideOfContainer = ingredientIndex => {
    return (
      (!areIngredientsOnTheLeftSide && !isEven(ingredientIndex)) ||
      (areIngredientsOnTheLeftSide && isEven(ingredientIndex))
    )
  }

  const getIngredientMargin = (ingredientIndex, isMarginLeft) => {
    return !isEven(ingredientIndex) &&
      (isMarginLeft ? !areIngredientsOnTheLeftSide : areIngredientsOnTheLeftSide)
      ? 5.75
      : 0
  }
  const getIngredientMarginLeft = ingredientIndex => getIngredientMargin(ingredientIndex, true)
  const getIngredientMarginRight = ingredientIndex => getIngredientMargin(ingredientIndex, false)

  const imageMaxWidth = DESKTOP_IMAGE_MAX_WIDTH_BY_POSITION[position]
  const isRowReverse = areIngredientsOnTheLeftSide

  return (
    <Box zIndex={4}>
      <Stack
        direction={isRowReverse ? 'row-reverse' : 'row'}
        gap={2}
        mr={getIngredientMarginRight(position)}
        ml={getIngredientMarginLeft(position)}
        justifyContent={
          isIngredientOnTheLeftSideOfContainer(position)
            ? isRowReverse
              ? 'flex-end'
              : 'flex-start'
            : isRowReverse
            ? 'flex-start'
            : 'flex-end'
        }
      >
        <Box
          py={`calc(${spacing(hexShapeScaleToUse * 11.27)} - 17.4%)`}
          pr={{ xs: isEven(position) ? 2.375 : 0, md: 0 }}
          pl={{ xs: isEven(position) ? 0 : 5.875, md: 0 }}
        >
          <ProvenHexShape
            color={theme.palette.gray.blueGray}
            opacity={0.2}
            scale={hexShapeScaleToUse}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={spacing(16)}
              height={spacing(16)}
            >
              <Box
                component="img"
                width="auto"
                maxWidth={imageMaxWidth}
                height="auto"
                src={ingredientData.ingredient_img}
                alt={ingredientData.ingredient_name}
              />
            </Box>
          </ProvenHexShape>
        </Box>
        <Stack zIndex={4} width={spacing(19)} alignSelf="center">
          <Typography variant="title2" color="gray.gray">
            {ingredientData.ingredient_name}
          </Typography>
          <Typography variant="footnote" color="gray.blueGray">
            {ingredientData.ingredient_description}
          </Typography>
          <Typography variant="title1" color="primary.main">
            {formatPercentage(ingredientData.ingredient_percentage)}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
