import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ProvenHexShape from '../../../../../../shared/proven-hex-shape'
import { createProvenTheme } from '../../../../../../../styles/theme-proven'
import { formatPercentage } from '../../../../../../../utils/helpers'

const theme = createProvenTheme()
const spacing = theme.spacing

export function MobileIngredient({ ingredientData }) {
  return (
    <Box zIndex={4}>
      <Stack direction="row" gap={3.5}>
        <Box>
          <ProvenHexShape color={theme.palette.gray.blueGray} opacity={0.2} scale={0.8}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={spacing(12)}
              height={spacing(12)}
            >
              <Box
                component="img"
                width="auto"
                maxWidth={spacing(11.5)}
                height="auto"
                src={ingredientData.ingredient_img}
                alt={ingredientData.ingredient_name}
              />
            </Box>
          </ProvenHexShape>
        </Box>
        <Stack
          zIndex={4}
          width={spacing(19)}
          alignSelf="center"
          sx={{ transform: 'translateY(-12%)' }}
        >
          <Typography variant="title2" color="gray.gray">
            {ingredientData.ingredient_name}
          </Typography>
          <Typography variant="footnote" color="gray.blueGray">
            {ingredientData.ingredient_description}
          </Typography>
          <Typography variant="title1" color="primary.main">
            {formatPercentage(ingredientData.ingredient_percentage)}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
