import React, { useRef, useState } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material'

import { BUILDER_CDN } from '../../../../../../constants/endpoints'
import { createProvenTheme } from '../../../../../../styles/theme-proven'
import { formatPercentage } from '../../../../../../utils/helpers'
import { Image } from 'components/builder/blocks/shared/Image'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ProvenShape from '../../../../../shared/proven-shape'
import AnimatedNumber from '../../../../../shared/AnimatedNumber'

const theme = createProvenTheme()
const spacing = theme.spacing

const LEFT_SIDE_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fef012169f1a34277a1e2973041270dfa`

function Lifestyle({ lifestyle, lifeStyleDescription }) {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const percentageRef = useRef()

  const [openedIndex, setOpenedIndex] = useState(null)

  return (
    <>
      <Paper
        sx={{
          position: 'absolute',
          right: 0,
          height: { xs: spacing(25), lg: spacing(57.5) },
          width: { xs: spacing(37.5), lg: spacing(92.375) },
          boxShadow: 'unset',
          borderRadius: 'unset',
          mixBlendMode: 'multiply',
          transform: !isLgUp ? `translateY(${spacing(48)})` : undefined
        }}
      >
        <Image
          lazy
          image={LEFT_SIDE_BACKGROUND_IMG_URL}
          backgroundSize={isLgUp ? undefined : 'contain'}
          backgroundPosition={
            isLgUp ? `top 0 right ${spacing(-20.375)}` : `top 0 right ${spacing(-5)}`
          }
          aspectRatio={1.607}
        />
      </Paper>
      <Box
        sx={{
          pt: { xs: 10.5, lg: 12 },
          pb: { xs: 11, lg: 16 },
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(4, 1fr)',
            md: 'repeat(12, 1fr)',
            lg: 'repeat(12, 1fr)'
          },
          gridTemplateRows: {
            xs: 'auto auto auto 1fr',
            md: 'auto auto 1fr'
          },
          gridTemplateAreas: {
            xs: `"title title title title"
                 "description description description description"
                 "percentage percentage percentage percentage"
                 "envFactor envFactor envFactor envFactor"`,
            md: `"title title title title title . envFactor envFactor envFactor envFactor envFactor envFactor"
                 "description description description description description . envFactor envFactor envFactor envFactor envFactor envFactor"
                 "percentage percentage percentage percentage percentage . envFactor envFactor envFactor envFactor envFactor envFactor"`
          },
          columnGap: 3
        }}
      >
        <Typography
          alignSelf="end"
          sx={{
            gridArea: 'title',
            pb: { xs: 1, lg: 2 },
            '@media (max-width:900px)': { fontSize: '42px' }
          }}
          variant="h2"
        >
          Based on Your
          <br />
          <Typography component="span" variant="inherit" color="primary.main">
            Style of Life
          </Typography>
        </Typography>
        <Typography sx={{ gridArea: 'description', pb: 2 }} variant="body2" color="gray.blueGray">
          {lifeStyleDescription && lifeStyleDescription?.trim()
            ? lifeStyleDescription
            : `Your Personalized Skincare is formulated to complement your lifestyle, including your stress level, travel, blue light exposure, sleep amount & diet.`}
        </Typography>

        <Box gridArea="percentage" mb={{ xs: 6, lg: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ProvenShape
              zIndex={1}
              baseWidth={isMdUp ? 3.6 : 3}
              baseHeight={isMdUp ? 3.6 : 3}
              viewBox="0 0 32 32"
              name="circle"
              backgroundColor="gray.lightBlue"
              justifyContent="flex-start"
            >
              <Typography
                pr={2}
                pl={2}
                zIndex={4}
                variant="h3"
                color="primary.main"
                display="flex"
                alignItems="center"
                ref={percentageRef}
              >
                <AnimatedNumber value={40} parentRef={percentageRef} />%
              </Typography>
            </ProvenShape>
            <Typography alignSelf="start" zIndex={4} variant="title2" color="primary.main">
              of customers experience transformative skin shifts each season, demanding a fresh
              routine
            </Typography>
          </Box>
        </Box>

        <Box
          gridArea="envFactor"
          zIndex={4}
          sx={{
            boxShadow: 3,
            py: 3,
            px: { xs: 2, md: 5 },
            borderRadius: 2,
            background: 'white',
            overflow: 'hidden'
          }}
        >
          <Box>
            {lifestyle.slice(0, 4).map((env, i) => {
              const isOpened = openedIndex === i

              return (
                <Box zIndex={4} key={`env${i}`} mt={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setOpenedIndex(isOpened ? null : i)}
                  >
                    <Stack direction="row" alignItems="center">
                      <Box
                        sx={{
                          width: spacing(4),
                          mr: 2,
                          height: 'auto',
                          position: 'relative'
                        }}
                      >
                        <Image
                          lazy
                          backgroundSize="contain"
                          image={env.vital_icon_link}
                          altText={env.vital_img_name}
                          aspectRatio={1}
                        />
                      </Box>
                      <Stack direction="column" sx={{ textTransform: 'uppercase' }}>
                        <Typography variant="title1" color="gray.elysian">
                          {env.vital_title}
                        </Typography>
                        {env.tags_on_congrats && (
                          <Typography variant="footnoteLight" color="gray.blueGray">
                            {env.tags_on_congrats}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <IconButton size="small" sx={{ color: theme.palette.gray.elysian }}>
                      {isOpened ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </Box>

                  {isOpened && (
                    <Box pl={6}>
                      <Typography variant="body2" color="gray" my={2}>
                        {env.body}
                      </Typography>
                      <Stack flexWrap="wrap" direction={isLgUp ? 'row' : 'column'} gap={3}>
                        {(env.ingredients || [])
                          .filter(ing => ing && ing.ingredient_img)
                          .slice(0, 3)
                          .map((ingredient, index) => (
                            <Stack
                              key={`env${i}-ing${index}`}
                              gap={isLgUp ? 3 : 2}
                              pb={isLgUp ? 1 : 0}
                              direction="row"
                              alignItems="center"
                              width={isLgUp ? 'calc(50% - 12px)' : '100%'}
                            >
                              <Box>
                                <Image
                                  lazy
                                  image={ingredient.ingredient_img}
                                  altText={ingredient.ingredient_name}
                                  sx={{
                                    width: { xs: spacing(6.5), md: spacing(9) },
                                    height: 'auto',
                                    maxHeight: { xs: spacing(6.5), md: spacing(9) }
                                  }}
                                />
                              </Box>

                              <Typography component="p" variant="footnote" color="gray.elysian">
                                {ingredient.ingredient_name}
                                <Typography
                                  component="p"
                                  variant="title1"
                                  color="gray.blueGray"
                                  mt={0.5}
                                >
                                  {formatPercentage(ingredient.ingredient_percentage)}
                                </Typography>
                              </Typography>
                            </Stack>
                          ))}
                      </Stack>
                    </Box>
                  )}

                  {i !== 3 && (
                    <Divider
                      sx={{
                        borderColor: theme.palette.gray.elysian,
                        opacity: 0.2,
                        mt: 2
                      }}
                    />
                  )}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Lifestyle
