import React from 'react'
import { Box, Button, Dialog, IconButton, Slide, useMediaQuery } from '@mui/material'
import Slider from 'react-slick'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ProductDetailSlide({ imageList, selectedImageIndex, handleClose }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  const settings = {
    customPaging: function (i) {
      const image = imageList[i]
      return <img src={image} key={i} />
    },
    dots: true,
    infinite: true,
    dotsClass: 'slick-dots slick-thumb',
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    initialSlide: selectedImageIndex
  }

  return (
    <Dialog
      fullScreen={isMobile}
      open
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          maxWidth: '65vh',
          padding: !isMobile ? '32px' : '0px'
        }
      }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%"
        sx={{ backgroundColor: 'white' }}
      >
        {isMobile ? (
          <Box sx={{ p: 2, position: 'absolute', top: 0, left: 0 }}>
            <Button
              variant="text"
              sx={{ textTransform: 'initial', p: 0, height: 'fit-content' }}
              onClick={handleClose}
            >
              <ChevronLeftIcon />
              Back
            </Button>
          </Box>
        ) : (
          <Box sx={{ width: '100%', textAlign: 'right', mb: 4 }}>
            <IconButton sx={{ p: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            marginTop: isMobile ? '-70px' : 0,
            paddingBottom: isMobile ? 0 : '96px',
            minWidth: 0,
            minHeight: 0,
            '& .slick-dots': {
              display: 'flex !important',
              alignItems: 'center',
              justifyContent: 'center',
              position: isMobile ? 'fixed' : 'absolute',
              bottom: isMobile ? 0 : '-96px',
              padding: isMobile ? '0 16px 20px 16px' : 0,
              gap: isMobile ? '12px' : '16px'
            },
            '& .slick-thumb li': {
              width: isMobile ? '50px' : '64px',
              height: isMobile ? '50px' : '64px',
              margin: 0
            },
            '& .slick-thumb img': {
              maxWidth: '100%',
              borderRadius: 1,
              height: 'auto'
            },
            '& .slick-dots .slick-active img': {
              border: '3px solid #ffea79'
            }
          }}
        >
          <Slider {...settings}>
            {(imageList || []).map((image, i) => (
              <Box component="img" src={image} key={i} sx={{ borderRadius: isMobile ? 0 : 2.5 }} />
            ))}
          </Slider>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ProductDetailSlide
